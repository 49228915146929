import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/WK/Private/Projects/Gatsby/MKF/src/templates/Page.tsx";
export const data = [{
  title: '',
  texts: [`Mit der nachfolgenden Datenschutzerklärung werden Sie als Nutzer dieses Angebots der Firma Mobiler Kranken Fahrservice, Auf der Mainhöhe 6, 65451 Kelsterbach, Tel.: 0800 883 77 26, über die Erfassung und Verwendung personenbezogener Daten beim Besuch und der Nutzung unserer Webseiten informiert.`, `Die gesetzlichen Bestimmungen des Datenschutzes, wie sie sich etwa im Bundesdatenschutzgesetz (BDSG) sowie Telemediengesetz (TMG) finden, werden von uns selbstverständlich beachtet, den Schutz Ihrer Daten nehmen wir sehr ernst.`, `Keine Anwendung findet diese Datenschutzerklärung auf Internetseiten welche Sie durch Hyperlinks auf dieser Internetseite erreichen können.`]
}, {
  title: 'ALLGEMEINE DATENERHEBUNG UND DATENVERARBEITUNG',
  texts: [`Bei jedem Besuch und der Nutzung der Internetseiten des Anbieters werden von diesem oder seitens des Webspace-Providers als Zugriffsdaten IP-Adresse sowie Provider des anfragenden Rechners, abgerufene Webseite sowie abgerufene Daten, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Erkennungsdaten des vom anfragenden Rechner verwendeten Browsers und Betriebssystems sowie die zuvor besuchte Webseite (Webseite, von welcher der Zugriff auf das Internetangebot des Anbieters erfolgt) erfasst.`, `Die Erhebung und Verarbeitung der genannten Zugriffsdaten erfolgt nur zum Zwecke der Systemadministration, der Systemsicherheit, der Optimierung des Angebots sowie statistischer Auswertungen. Eine nachträgliche Auswertung der Zugriffsdaten behält sich der Anbieter für den Fall einer rechtswidrigen Nutzung und eines rechtswidrigen Angriffs auf die Internetseiten des Anbieters ausdrücklich vor, soweit hierfür ein hinreichender Verdacht aufgrund konkreter Anhaltspunkte besteht.`]
}, {
  title: 'PERSONENBEZOGENE DATEN',
  texts: [`Begriff der personenbezogenen Daten`, `Personenbezogene Daten sind nach § 3 Abs. 1 BDSG Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Zu diesen Daten gehören beispielsweise Name, Adresse, E-Mail-Adresse oder Telefonnummer. Nutzungsdaten zählen ebenso zu den personenbezogenen Daten.`, `Erhebung personenbezogener Daten`, `Personenbezogene Daten werden vom Anbieter nur erhoben, soweit dies gesetzlich erlaubt ist oder Sie in die Erhebung der Daten einwilligen. Eine Einwilligung wird im Falle Ihrer Kontaktaufnahme mit dem Anbieter per E-Mail oder mittels Kontaktformular von diesem angenommen, Ihre Daten werden in diesem Fall zum Zwecke der Bearbeitung Ihres Anliegens gespeichert. Nach vollständigem Abschluss der Bearbeitung werden Ihre Daten gelöscht, soweit keine gesetzliche Aufbewahrungspflicht entgegensteht.`]
}, {
  title: 'COOKIES',
  texts: [`Der Anbieter verwendet auf seinen Internetseiten Cookies zur Steigerung der Benutzerfreundlichkeit des Internetangebots sowie zur Erfassung statistischer Daten zur fortlaufenden Verbesserung dieses Internetangebots. Falls Sie eine Verwendung von Cookies bei Besuchen des Internetangebots des Anbieters nicht wünschen, haben Sie die Möglichkeit, entsprechende Einstellungen in Ihrem Internetbrowser vorzunehmen. Hierfür werden Sie gebeten, in den Einstellungen Ihres Internetbrowsers auszuwählen, ob Sie die Speicherung von Cookies nur eingeschränkt, oder gar nicht zulassen wollen. Der Anbieter weist jedoch darauf hin, dass es ohne Cookies zu Funktionseinschränkungen dieses Internetangebots kommen kann.`]
}, {
  title: 'WEB ANALYSE',
  texts: [`Dem Anbieter ist es nach § 15 Abs. 3 TMG gestattet, für Zwecke der Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung der Telemedien Nutzungsprofile bei Verwendung von Pseudonymen erstellen, sofern der Nutzer dem nicht widerspricht. Der Anbieter verwendet zu diesem Zweck die datenschutzfreundliche Software Piwik (www.piwik.org), um die Zugriffe auf das bereit gestellte Internetangebot statistisch auswerten zu können. Die Software Piwik verwendet kleine Textdateien, welche auf den auf das Internetangebot des Anbieters zugreifenden Computern gespeichert werden (Cookies) und so das Analysieren der konkreten Benutzung des Internetangebots des Anbieters ermöglichen. Die so erhobenen Daten werden nur anonymisiert gespeichert, eine persönliche Identifizierung ist damit nicht verbunden. Dies betrifft auch Ihre IP-Adresse, welche nicht zur Analyse Ihres Nutzungsverhaltens, sondern nur zur Erstellung eines anonymisierten Pseudonyms verwendet wird. Um dies sicherzustellen, erfolgt die Anonymisierung sofort nach der Erhebung und vor der Speicherung der IP-Adresse. Eine Zusammenführung mit personenbezogenen Daten des Trägers des Pseudonyms findet nicht statt. Falls Sie eine Verwendung von Cookies bei Besuchen des Internetangebots des Anbieters nicht wünschen, haben Sie die Möglichkeit, entsprechende Einstellungen in Ihrem Internetbrowser vorzunehmen. Hierfür werden Sie gebeten, in den Einstellungen Ihres Internetbrowsers auszuwählen, ob Sie die Speicherung von Cookies nur eingeschränkt, oder gar nicht zulassen wollen. Der Anbieter weist jedoch darauf hin, dass es ohne Cookies zu Funktionseinschränkungen dieses Internetangebots kommen kann. Sie können der Datenerhebung und Datenspeicherung, wie vorstehend beschrieben, jederzeit mit Wirkung für die Zukunft widersprechen.`]
}, {
  title: 'AUSKUNFTS- UND BERICHTIGUNGSRECHTE',
  texts: [`Sie haben das Recht, Auskunft über die beim Anbieter über Sie gespeicherten personenbezogenen Daten unentgeltlich und auf entsprechenden formlosen Antrag an den unten aufgeführten Kontakt, zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung und Löschung Ihrer personenbezogenen Daten, sofern diesem Verlangen keine gesetzlichen Aufbewahrungspflichten entgegenstehen.`]
}, {
  title: 'KONTAKT UND WEITERGEHENDE HINWEISE',
  texts: [`Bei datenschutzrechtlichen Anliegen wenden Sie sich bitte an den Mobiler Kranken Fahrservice,`, ``, `Auf der Mainhöhe 6`, `65451 Kelsterbach`, `Tel.: 0800 883 77 26`, `E-Mail: info@mkf-service.com`, ``, `Der Anbieter weist darauf hin, dass sich datenschutzrechtliche Änderungen ergeben können. Diese Erklärung sollte von Ihnen daher nach einem gewissen Zeitablauf erneut gelesen werden.`]
}, {
  title: '',
  texts: [`© 2021 Mobiler Kranken Fahrservice`]
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Typography = makeShortcode("Typography");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box mdxType="Box">
  <div style={{
        backgroundColor: '#fff',
        padding: '32px 64px',
        margin: '32px 0'
      }}>
    <div style={{
          margin: '64px 0',
          width: '100%'
        }}>
      <Typography variant={'h3'} renderAs={'h1'} mdxType="Typography">
        Datenschutzerklärung
      </Typography>
    </div>
    {data.map((d, idx) => <div key={idx}>
        <div style={{
            margin: '64px 0 32px 0',
            width: '100%'
          }}>
          <Typography variant={'h4'} renderAs={'h2'} mdxType="Typography">
            {d.title}
          </Typography>
        </div>
        <div>
          {d.texts.map((dt, idx) => <div key={idx}>{dt ? <Typography mdxType="Typography">{dt}</Typography> : <br />}</div>)}
        </div>
      </div>)}
  </div>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      